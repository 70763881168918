import React, { useContext } from "react";
import { Link} from "react-router-dom";

import { Context }  from './../Auth.Context';
import { translate } from './../helpers/intl_helpers';

export default function SideBar() {
    const [authDetails,] = useContext(Context);

	return <>
        <section className="btm_sec max-zIndex">
            <div className="menu_container">
                <div className="navbar-fostrap">
                    <span /> <span /> <span />
                </div>
                <nav className="header_navigation">
                    <ul className="menu close">
                        {authDetails && authDetails._id ?
                            <>
                                <li className="menu-item">
                                    <Link to="/customer">
                                        {translate('sidebar.dashboard')}
                                    </Link>
                                </li>
                                {/* <li>
                                    <Link to="#">Edit Profile</Link>
                                </li>
                                <li>
                                    <Link to="#">Change Password</Link>
                                </li> */}
                                <li>
                                    <Link to="/customer/start-project">
                                        {translate("dashboard.start_project")}
                                    </Link>
                                </li>
                                {!authDetails.parent_id ?
                                    <li className="menu-item">
                                        <Link to="/customer/sub-user">
                                            {translate('sidebar.manage_sub_user')}
                                        </Link>
                                    </li>
                                :null}
                            </>
                        :
                            <>
                                <li>
                                    <Link to="/login">Login</Link>
                                </li>
                                <li>
                                    <Link to="/sign-up">Sign Up </Link>
                                </li>
                            </>
                        }
                        <li>
                            <Link to="/cms/design-guide">{translate("dashboard.design_guide")}</Link>
                        </li>
                        <li>
                            <Link to="/cms/project-care">{translate("dashboard.project_care")}</Link>
                        </li>
                        <li>
                            <Link to="/cms/customer-care">{translate("dashboard.customer_care")}</Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </section>
	</>
}