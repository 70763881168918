import React, {lazy, Suspense} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import CustomerProtectedRoute from './CustomerProtectedRoute';
import PublicOnlyRoute from './PublicOnlyRoute';

import Layout from "./../layout/Layout";
const ScrollToTop =	lazy(()=> import("./../ScrollToTop"));

const Home 			=	lazy(()=> import("./../components/Home"));
const Cms 			=	lazy(()=> import("./../components/Cms"));
const Login			=	lazy(()=> import("./../components/Login"));
const ForgetPassword=	lazy(()=> import("./../components/ForgetPassword"));
const ResetPassword	=	lazy(()=> import("./../components/ResetPassword"));
const Signup		=	lazy(()=> import("./../components/Signup"));
const Page404		=	lazy(()=> import("./../components/Page404"));
const PersonalInfo	=	lazy(()=> import("./../components/PersonalInfo"));

const Dashboard				=	lazy(()=> import("./../components/Customer/Dashboard"));
const StartProject			=	lazy(()=> import("./../components/Customer/StartProject"));
const ProjectRegistered		=	lazy(()=> import("./../components/Customer/ProjectRegistered"));
const Projects				=	lazy(()=> import("./../components/Customer/Projects"));
const LaunchProject			=	lazy(()=> import("./../components/Customer/LaunchProject"));
const ManageProductData		=	lazy(()=> import("./../components/Customer/ManageProductData"));
const AddOrEditProductData	=	lazy(()=> import("./../components/Customer/AddOrEditProductData"));
const SubAdminList			=	lazy(()=> import("./../components/Customer/SubAdminList"));
const AddSubAdmin			=	lazy(()=> import("./../components/Customer/AddSubAdmin"));

export default function AppRoutes() {
	return (
		<BrowserRouter>
			<Suspense fallback={<div></div>}>
				<ScrollToTop />
				<Routes>
					<Route path="/" element={<Layout />}>
						<Route index element={<Home />} />
						<Route path="/cms/:page_slug" element={<Cms />} />

						<Route element={ <PublicOnlyRoute/> }>
							<Route path="/login" element={<Login />} />
							<Route path="/sign-up" element={<Signup />} />
							<Route path="/forgot-password" element={<ForgetPassword />} />
							<Route path="/personal-info/:validate_string" element={<PersonalInfo />} />
							<Route path="/reset-password/:validate_string" element={<ResetPassword />} />
						</Route>

						<Route path="customer" element={ <CustomerProtectedRoute/> }>
							<Route index={true} element={  <Dashboard /> }/>
							<Route path="projects/launch" element={<Projects pageType="launch" />} />
							<Route path="projects/launch/:projectId" element={<LaunchProject />} />
							<Route path="projects/launch/manage-data/:projectId" element={<ManageProductData pageType="launch" />} />
							<Route path="projects/launch/manage-data/add/:projectId" element={<AddOrEditProductData />} />
							<Route path="projects/launch/manage-data/edit/:projectId/:dataId" element={<AddOrEditProductData />} />
							<Route path="projects/view" element={<Projects pageType="view" />} />
							<Route path="projects/view/manage-data/:projectId" element={<ManageProductData pageType="view" />} />
							<Route path="projects/edit" element={<Projects pageType="edit" />} />
							<Route path="update-project/:projectId/:projectType" element={<StartProject pageType="update" />} />
							<Route path="projects/download" element={<Projects pageType="download" />} />
							<Route path="start-project" element={<StartProject />} />
							<Route path="start-project/card-set/registered" element={<ProjectRegistered />} />
							<Route path="start-project/card-only/registered" element={<ProjectRegistered />} />
							<Route path="start-project/lanyard-only/registered" element={<ProjectRegistered />} />
							<Route path="sub-user" element={<SubAdminList />} />
							<Route path="sub-user/add" element={<AddSubAdmin />} />
						</Route>

						<Route path="*" element={<Page404 />} />
					</Route>
				</Routes>
			</Suspense>
		</BrowserRouter>
	);
}