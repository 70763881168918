import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Cookies from 'universal-cookie';
import { Context }  from './../Auth.Context';

import SideBar 	 from "./../elements/SideBar";

const Layout = () => {
	const cookies =	new Cookies();
	const [userDetails, setUserDetails] = useState(cookies.get('user_details') || {});

	return (
		<>
			<Context.Provider value={[userDetails, setUserDetails]}>
				<section className="main_sec">
					<div className="container">						
						<Outlet />
						<SideBar />
					</div>					
				</section>
			</Context.Provider>
		</>
	)
};
export default Layout;
