import React, { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Context }  from './../Auth.Context';

export default function CustomerProtectedRoute() {
	const [userDetails,] = 	useContext(Context);
	const { pathname } = useLocation();

	if(!userDetails || !userDetails._id){
		return <Navigate to="/login" />;
	}else if(userDetails.parent_id) {
		if(pathname.indexOf("sub-user") !== -1){
			return <Navigate to="/customer" />;
		}
		return <Outlet />;
	}else {
		return <Outlet />;
	}
}// end CustomerProtectedRoute()

