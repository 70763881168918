import { createIntl } from "react-intl";
import messages_en from "./../locales/en.json";
import { DEFAULT_LANGUAGE_CODE, AM_PM_FORMAT_WITH_DATE, 	 }  from './../config/global_constants';
import moment from 'moment';

const messages = { 'en': messages_en };
const message	=	messages[DEFAULT_LANGUAGE_CODE];
const intl = createIntl({ locale: "en",   messages:  message })

/* translate the words */
export function translate(id, defaultMessage=null, constant){
	if(!defaultMessage) defaultMessage = id;
	let message = intl.formatMessage({id: id, defaultMessage: defaultMessage});
	if(constant && message && typeof constant === "string" && message.indexOf("%s") !== -1){
		message = message.replaceAll("%s",constant);
	}
	return message;
};

/* format the number/price in required format */
export function currency(amount, currency = "INR"){
	return intl.formatNumber(amount , {style: 'currency', currency: currency, minimumFractionDigits: 0, maximumFractionDigits: 2});
};

/* format the date in required format */
export function formatDate(dateToBeFormat, format = AM_PM_FORMAT_WITH_DATE){
	return moment(dateToBeFormat).format(format);
};

/* format the date in required format */
export function timeAgo(date){
	return moment(new Date(date)).fromNow();
};
