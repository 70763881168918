/* API request complete path */
export const API_URL = process.env.REACT_APP_HOST+'/';

/* Website Url */
export const WEBSITE_URL = process.env.REACT_APP_HOST+'/';

/* Key for API authentication */
export const API_HEADER_AUTH_KEY = process.env.REACT_APP_API_HEADER_AUTH_KEY;

/* Languages configurations */
export const ENGLISH_LANGUAGE_MONGO_ID	= "5a3a13238481824b077b23ca";
export const ENGLISH_LANGUAGE_CODE		= "en";

/* Default language configurations */
export const DEFAULT_LANGUAGE_CODE		= ENGLISH_LANGUAGE_CODE;
export const DEFAULT_LANGUAGE_ID		= ENGLISH_LANGUAGE_MONGO_ID;

/* Default country code */
export const  DEFAULT_COUNTRY_CODE	=	"+91";

export const LANGUAGES = [
	{"id" : ENGLISH_LANGUAGE_MONGO_ID, "name" : "English"}
];

/* Supported languages list */
export const LANGUAGE_CODES = {};
LANGUAGE_CODES[ENGLISH_LANGUAGE_MONGO_ID]	=	ENGLISH_LANGUAGE_CODE;

/** For currency symbol */
export const CURRENCY_SYMBOL = 'INR';

/** Date format **/
export const AM_PM_FORMAT_WITH_DATE		=	"YYYY/MM/DD hh:mm A";

/** API status */
export const STATUS_SUCCESS = "success";
export const STATUS_ERROR 	= "error";
export const ACTIVE		= 	1;

export const ROUND_PRECISION = 	2;

export const COMPLETE_CARD_SET 	= "1";
export const ONLY_CARD 			= "2";
export const ONLY_LANYARD 		= "3";

export const PARENT_PROJECT_TYPE = [
    {id: COMPLETE_CARD_SET, title : "Id Card Set ( Complete )"},
    {id: ONLY_CARD, title : "Cards Only"},
    {id: ONLY_LANYARD, title : "Lanyards Only"}
];

export const CARD_TYPE = [
    {id : "1", title : "PVC Cards Front Only", holderType: ["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25"] },
    {id : "2", title : "PVC Cards Front Back", holderType: ["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25"] },
    {id : "3", title : "Sticky Cards Front Only", holderType: ["26","27","28","29","30","31","32","33"] },
    {id : "4", title : "Sticky Cards Front Back", holderType: ["32","34","35","36","37","38"]},
    {id : "5", title : "PVC Sticky Cards Front Only", holderType: ["26","27","28","29","30","31","32","33"]},
    {id : "6", title : "PVC Sticky Cards Front Back", holderType: ["32","34","35","36","37","38"]},
];

export const CARD_TYPE_FOR_ONLY_CARD = [
    {id : "7", title : "PVC ID Cards Front Only", },
    {id : "8", title : "PVC ID Cards Front Back"},
    {id : "9", title : "Membership Cards"},
    {id : "10", title : "Privilege Cards"},
    {id : "11", title : "Warranty Cards"},
    {id : "12", title : "Loyalty Cards"},
];

export const HOLDER_TYPE  = [
    {id : "1", title : "Regular E2 Transparent"},
    {id : "2", title : "Regular E2 Red Color"},
    {id : "3", title : "Regular E2 Green Color"},
    {id : "4", title : "Regular E2 Blue Color"},
    {id : "5", title : "Regular E2 Yellow"},
    {id : "6", title : "Regular Ch1 Transparent"},
    {id : "7", title : "Hut Transparent"},
    {id : "8", title : "C2 Transparent"},
    {id : "9", title : "HH Transparent"},
    {id : "10", title : "C2 Extra Transparent"},
    {id : "11", title : "HH Extra Transparent"},
    {id : "12", title : "X2 Extra Transparent"},
    {id : "13", title : "Briefcase"},
    {id : "14", title : "HH Acrylic"},
    {id : "15", title : "EP Executive White"},
    {id : "16", title : "RE Unique White"},
    {id : "17", title : "RE Transparent"},
    {id : "18", title : "RE Unbreakable Transparent"},
    {id : "19", title : "Metal Holder Golden"},
    {id : "20", title : "Metal Holder Silver"},
    {id : "21", title : "Metal Holder Red"},
    {id : "22", title : "Metal Holder Black"},
    {id : "23", title : "Metal Holder Blue"},
    {id : "24", title : "Plastic IMP A1 Blue"},
    {id : "25", title : "Plastic IMP A1 Red"},
    {id : "26", title : "P1 White"},
    {id : "27", title : "L1 White"},
    {id : "28", title : "L1 Red"},
    {id : "29", title : "L1 Blue"},
    {id : "30", title : "L1 Green"},
    {id : "31", title : "L1 Yellow"},
    {id : "32", title : "L1 Transparent"},
    {id : "33", title : "L1 Unbreakable Transparent"},
    {id : "34", title : "L1 Transparent Unbreakable"},
    {id : "35", title : "P2 White"},
    {id : "36", title : "Small U White"},
    {id : "37", title : "L2 White"},
    {id : "38", title : "L2 Transparent Unbreakable"}
];

export const HOOK_TYPE = [
    {id: "1", title : "Regular J Hook" },
    {id: "2", title : "Chimti" },
    {id: "3", title : "Plastic Hook" },
    {id: "4", title : "Lever Hook" },
    {id: "5", title : "Oval Hook" },
    {id: "6", title : "Plastic J Hook" },
    {id: "7", title : "Break Trough" },
];

export const LANYARD_COLOR = [
	{id : "1", title : "Red"},
    {id : "2", title : "Mehroon"},
    {id : "3", title : "Navy Blue"},
    {id : "4", title : "Royal Blue"},
    {id : "5", title : "Dark Green"},
    {id : "6", title : "Light Green"},
    {id : "7", title : "Yellow"},
    {id : "8", title : "Brown"},
    {id : "9", title : "Black"},
    {id : "10", title : "Grey"},
    {id : "11", title : "Purple"},
    {id : "12", title : "Orange"},
    {id : "13", title : "Red & Navy Blue"},
    {id : "14", title : "Green & Red"},
    {id : "15", title : "Golden"},
    {id : "16", title : "Silver"}
];

export const HOOK_OR_HOLDER_TYPE = [
    { "id": "1", "title": "Regular J Hook" },
    { "id": "2", "title": "Chimti" },
    { "id": "3", "title": "Plastic Hook" },
    { "id": "4", "title": "Lever Hook" },
    { "id": "5", "title": "Oval Hook" },
    { "id": "6", "title": "Regular E2 Transparent" },
    { "id": "7", "title": "Regular E2 Red Color" },
    { "id": "8", "title": "Regular E2 Green Color" },
    { "id": "9", "title": "Regular E2 Blue Color" },
    { "id": "10", "title": "Regular E2 Yellow" },
    { "id": "11", "title": "Regular Ch1 Transparent" },
    { "id": "12", "title": "Hut Transparent" },
    { "id": "13", "title": "C2 Transparent" },
    { "id": "14", "title": "HH Transparent" },
    { "id": "15", "title": "C2 Extra Transparent" },
    { "id": "16", "title": "HH Extra Transparent" },
    { "id": "17", "title": "X2 Extra Transparent" },
    { "id": "18", "title": "Briefcase" },
    { "id": "19", "title": "HH Acrylic" },
    { "id": "20", "title": "EP Executive White" },
    { "id": "21", "title": "RE Unique White" },
    { "id": "22", "title": "RE Transparent" },
    { "id": "23", "title": "RE Unbreakable Transparent" },
    { "id": "24", "title": "Metal Holder Golden" },
    { "id": "25", "title": "Metal Holder Silver" },
    { "id": "26", "title": "Metal Holder Red" },
    { "id": "27", "title": "Metal Holder Black" },
    { "id": "28", "title": "Metal Holder Blue" },
    { "id": "29", "title": "Plastic IMP A1 Blue" },
    { "id": "30", "title": "Plastic IMP A1 Red" },
    { "id": "31", "title": "Plastic J Hook" },
    { "id": "32", "title": "Break Trough" }
];

export const LANYARD_NONE = "9";
export const LANYARD_TYPE = [
    {
        id : "1", title : "14mm Flat Border", onlyLanyard: true, screenPrinted : true,
        colors: ["1","2","3","4","5","6","7","8","9","10","11","12","13","14"], hookType: ["1","2","3","4","5"],
        onlyLanyardColors: ["1","2","3","4","5","6","7","8","9","10","11","12","13","14"],
        hookOrHolderType: ["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31","32"],
    },
    {
            id : "2", title : "12mm Tube Type",   onlyLanyard: true, screenPrinted : true,
            colors: ["1","2","3","4","5","6","7","8","9","10","11","12"], hookType: ["1","2","3","4","5"],
            onlyLanyardColors: ["1","2","3","4","5","6","7","8","9","10","11","12"],
            hookOrHolderType: ["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31","32"],
    },
    {
        id : "3", title : "12mm Satin Color", onlyLanyard: true, screenPrinted : true,
        colors: ["1","2","3","4","5","6","7","8","9","10","11","12"], hookType: ["1","2","3","4","5"] ,
        onlyLanyardColors: ["1","2","3","4","5","6","7","8","9","10","11","12","14","15"],
        hookOrHolderType: ["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31","32"],
    },
    {
        id : "4", title : "16mm Satin Color", onlyLanyard: true, screenPrinted : true,
        colors: ["1","2","3","4","5","6","7","8","9","10","11","12"], hookType: ["1","3","4","5","6","7"],
        onlyLanyardColors: ["1","2","3","4","5","6","7","8","9","10","11","12","14","15"],
        hookOrHolderType: ["1","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31","32"],
    },
    {
        id : "5", title : "20mm Satin Color", onlyLanyard: true, screenPrinted : true,
        colors: ["1","2","3","4","5","6","7","8","9","10","11","12"], hookType: ["1","3","4","5","6","7"],
        onlyLanyardColors: ["1","2","3","4","5","6","7","8","9","10","11","12","14","15"],
        hookOrHolderType: ["1","3","4","5"],
    },
    {
        id : "6", title : "12mm Multicolor",  onlyLanyard: true, customDesign: true, hookType: ["1","2","3","4","5"],
        hookOrHolderType: ["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31","32"],
    },
    {
        id : "7", title : "16mm Multicolor",  onlyLanyard: true, customDesign: true, hookType: ["1","3","4","5","6","7"],
        hookOrHolderType: ["1","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31","32"]
    },
    {
        id : "8", title : "20mm Multicolor",  onlyLanyard: true, customDesign: true, hookType: ["1","3","4","5","6","7"],
        hookOrHolderType: ["1","3","4","5"],
    },
    {id : LANYARD_NONE, title : "None"}
];

export const LANYARD_CUSTOM_DESIGN  =   "custom";
export const LANYARD_FIXED_DESIGN   =   "fixed";

export const CARD_SHAPE_HORIZONTAL  =   "horizontal";
export const CARD_SHAPE_VERTICAL    =   "vertical";

export const CARD_DESIGN_CUSTOM         =   "custom";
export const CARD_DESIGN_TEMPLATE       =   "template";
export const CARD_DESIGN_CARD_TO_CARD   =   "card_to_card";

export const YES =  "yes";
export const NO =   "no";

export const LANYARD_DESIGN_TEMPLATES =  [
    {id : "1", title : "DH01"},
    {id : "2", title : "DH02"},
    {id : "3", title : "DH03"},
    {id : "4", title : "DH04"},
    {id : "5", title : "DH05"},
    {id : "6", title : "DH06"},
]

export const CARD_DESIGN_TEMPLATE_LIST = [
    {id : "1", title : "Template 01"},
    {id : "2", title : "Template 02"},
    {id : "3", title : "Template 03"},
    {id : "4", title : "Template 04"},
    {id : "5", title : "Template 05"},
    {id : "6", title : "Template 06"},
]